import axios from 'axios';

export async function request(options) {
  try {
    console.log(`[API] request: ${JSON.stringify(options, null, 2)}`);
    const result = await axios(options);
    console.log(`[API] status: ${result?.status}`);

    return result;
  } catch (err) {
    const status = err?.response?.status;
    const data = err?.response?.data;
    const msg = err?.message ?? err;

    const log = [
      status ? `status: ${status}` : null,
      data ? `data: ${JSON.stringify(data)}` : null,
      msg ? `msg: ${msg}` : null
    ]
      .filter((el) => !!el)
      .join(', ');

    console.log(`[API] error ${log}`);

    throw err;
  }
}

export const getHost = () => {
  if (window.location && window.location.href && window.location.href.indexOf(':3000') !== -1) {
    const r = window.location.href.split(':3000');
    if (r[0]) {
      return `${r[0]}:9000`;
    }
  }

  return '';
};
