import { request, getHost } from './utils';

export const getParty = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/party`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};

export const n = () => {};
