/* eslint-disable */
export default {
  "ID": "zh-TW",
  "not_started": "活動未開始",
  "log_in": "登入",
  "log_out": "登出",
  "log_in_loggin": "登入中",
  "log_in_message": "登入之後才能觀看內容",
  "log_in_message_session": "登入之後才能參與活動",
  "email": "帳號",
  "password": "密碼",
  "employee_id": "員工編號",
  "employee_id_lenovo": "IT Code",
  "name": "姓名",
  "department": "單位",
  "account_banned": "帳號停權中",
  "account_error": "帳號或密碼錯誤",
  "account_error_employeeId": "ID不存在",
  "account_error_employeeId_lenovo": "IT Code 不存在",
  "account_not_exist": "帳號不存在",
  "error": "發生錯誤",
  "input_email": "請輸入帳號",
  "input_password": "請輸入密碼",
  "input_employee_id": "請輸入員工編號",
  "input_employee_id_lenovo": "請輸入 IT Code",
  "input_name": "請輸入姓名",
  "input_department": "請輸入單位",
  "chat_room": "聊天室",
  "award_result": "得獎結果",
  "input_message": "輸入訊息",
  "send": "傳送",
  "open_link": "開啟連結",
  "open_game": "進入遊戲",
  "award_list": "中獎名單",
  "award_my": "我的獎項",
  "awards": "獎項",
  "no_data_awards": "目前沒有獎項",
  "no_data_sessions": "目前沒有場次",
  "message_updated": "已更新",
  "winner": "得獎人",
  "souvenirs": "禮品項目",
  "dependants": "眷屬人數",
  "input_dependants": "請選擇眷屬人數",
  "input_souvenirs": "請選擇禮品",
  "choose_souvenirs": "選禮品",
  "confirm": "確認",
  "confirm_message": "確認後不可再修改，如有疑問請洽管理人員",
  "checkin_qrcode_message": "請出示此 QR Code 供工作人員掃描",
  "game_enter": "進入遊戲",
  "game_qrcode_message": "掃描Qrcode或點擊按鍵進入遊戲",
  "": "",
  "mission_title": "叢林集點闖關遊戲",
  "mission_currently": "目前點數",
  "mission_used": "已使用",
  "mission_points": "點",
  "mission_redeem": "兌換",
  "mission": "關卡",
  "mission_staff_only": "工作人員操作按鈕",
  "mission_redeem_error": "哎呀！您的點數不足。請選擇另一個禮物兌換。",
  "mission_confirm": "確定要兌換獎品嗎？",
  "mission_opt_2": "集兩點：動物頭箍、螢光鈴鼓或沙鈴",
  "mission_opt_3": "集三點：尖叫雞、啤酒",
  "mission_opt_4": "集四點：精釀啤酒、多肉小盆栽",
  "yes": "是",
  "no": "否",
  "cancel": "取消",
  "profile_points_title": "電子餐券",
  "points_currently": "剩餘點數",
  "points_used": "已消費點數",
  "points_unit": "點",
  "points_detail": "消費紀錄",
  "points_add": "加值",
  "points_deduct": "扣點",
  "updated_at": "更新時間",
  "use_points_btn": "使用點數",
  "booth_drawer_title": "請輸入消費攤位代碼或掃描店家 QR Code 來支付費用",
  "booth_id_label": "攤位代碼",
  "booth_id_error_empty": "請輸入攤位代碼",
  "booth_id_error_incorrect": "請輸入正確的攤位代碼",
  "booth_drawer_enter_amount": "輸入點數",
  "booth_title": "攤位",
  "transfer_button": "支付",
  "close_button": "關閉",
  "transfer_desc": "您已成功支付 {{amount}} 點",
  "result_success_title": "Success!",
  "result_fail_title": "哎呀！ 操作失敗",
  "result_network_error": "請確認網路連線並再試一次",
  "points_no_data": "目前沒有紀錄",
  "message_booth_not_found": "查無此攤位",
  "message_insufficient_points": "您的點數不足",
  "message_cant_use_point_until_checkin": "尚未報到，無法使用點數",
  "transfer_confirm_text": "確認要支付 {{amount}} 點給 {{booth}} 嗎？"
};
