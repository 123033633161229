import API from '../apis';

export default class Party {
  static getParty = async () => {
    const party = await API.party.getParty();

    return {
      name: party.data?.name ?? '',
      awardList: party.data?.awardList ?? null,
      relative: party.data?.relative ?? false,
      status: party.data?.status,
      canUseEmployeeIdLogin: party.data?.canUseEmployeeIdLogin,
      canUseAnonymousLogin: party.data?.canUseAnonymousLogin,
      canAutoLogin: party.data?.canAutoLogin,
      teamCount: party.data.teamCount,
      enableSession: party.data?.enableSession ?? false
    };
  }
}
