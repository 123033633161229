import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en-US.gen';
import zh from './locales/zh-TW.gen';

const languageDetector = new LanguageDetector(null, { order: ['navigator'] });

i18n
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      'en-US': {
        translation: en
      },
      'zh-TW': {
        translation: zh
      }
    }
  });

export default i18n;
