/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import i18n from '../../../I18n';

import ClientLayout from '../../../components/ClientLayout';

import loginFormStyles from './LoginPage_loginForm.module.css';
import styles from './LoginPage.module.css';

@observer
class Login extends React.Component {
  firstInputRef = React.createRef();

  secondInputRef = React.createRef();

  buttonRef = React.createRef();

  fakeButton = (
    <div
      className={cn(loginFormStyles.loginButton, loginFormStyles.loginButton_layout)}
      onClick={() => this.buttonRef.current.click()}
    >
      <div className={cn(loginFormStyles.text, loginFormStyles.text_layout)}>
        <div className={loginFormStyles.text_spacer} />
        <h3 className={cn(loginFormStyles.loginText, loginFormStyles.loginText_layout)}>{i18n.t('log_in')}</h3>
        <div className={loginFormStyles.text_spacer1} />
        <div className={loginFormStyles.text_item}>
          <RightOutlined className={loginFormStyles.icon_layout} style={{ color: 'white' }} />
        </div>
      </div>
    </div>
  )

  render() {
    let form = null;
    if (this.props.useEmployeeId && !this.props.useAnonymous) {
      form = (
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          onFinish={(fields) => this.props.onLoginClick(fields, 'useEmployeeId')}
        >
          <div onClick={() => this.firstInputRef.current.focus()}>
            <h4 className={cn(loginFormStyles.accountTitle, loginFormStyles.accountTitle_layout)}>{i18n.t('employee_id')}</h4>
          </div>
          <Form.Item
            name="employeeId"
            rules={[
              {
                required: true,
                message: i18n.t('input_employee_id')
              }
            ]}
          >
            <Input
              className={cn(loginFormStyles.accountInput, loginFormStyles.accountInput_layout)}
              ref={this.firstInputRef}
              onPressEnter={(e) => {
                e.preventDefault();
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              ref={this.buttonRef}
              style={{ display: 'none' }}
            >
              {i18n.t('log_in')}
            </Button>
          </Form.Item>
        </Form>
      );
    } else if (this.props.useAnonymous) {
      form = (
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          onFinish={(fields) => this.props.onLoginClick(fields, 'useAnonymous')}
        >
          <div onClick={() => this.firstInputRef.current.focus()}>
            <h4 className={cn(loginFormStyles.accountTitle, loginFormStyles.accountTitle_layout)}>{i18n.t('name')}</h4>
          </div>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: i18n.t('input_name')
              }
            ]}
          >
            <Input
              className={cn(loginFormStyles.accountInput, loginFormStyles.accountInput_layout)}
              ref={this.firstInputRef}
              onPressEnter={(e) => {
                e.preventDefault();
              }}
            />
          </Form.Item>

          <div onClick={() => this.secondInputRef.current.focus()}>
            <h4 className={cn(loginFormStyles.accountTitle, loginFormStyles.accountTitle_layout)}>{i18n.t('department')}</h4>
          </div>
          <Form.Item
            name="department"
            rules={[
              {
                required: true,
                message: i18n.t('input_department')
              }
            ]}
          >
            <Input
              className={cn(loginFormStyles.accountInput, loginFormStyles.accountInput_layout)}
              ref={this.secondInputRef}
              onPressEnter={(e) => {
                e.preventDefault();
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              ref={this.buttonRef}
              style={{ display: 'none' }}
            >
              {i18n.t('log_in')}
            </Button>
          </Form.Item>
        </Form>
      );
    } else {
      form = (
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          onFinish={(fields) => this.props.onLoginClick(fields, 'useAccount')}
        >
          <div onClick={() => this.firstInputRef.current.focus()}>
            <h4 className={cn(loginFormStyles.accountTitle, loginFormStyles.accountTitle_layout)}>{i18n.t('email')}</h4>
          </div>
          <Form.Item
            className={loginFormStyles.loginForm_item}
            name="email"
            rules={[
              {
                required: true,
                type: 'string',
                message: i18n.t('input_email')
              }
            ]}
          >
            <Input
              className={cn(loginFormStyles.accountInput, loginFormStyles.accountInput_layout)}
              ref={this.firstInputRef}
              onPressEnter={(e) => {
                e.preventDefault();
                this.secondInputRef.current.focus();
              }}
            />
          </Form.Item>

          <div onClick={() => this.secondInputRef.current.focus()}>
            <h4 className={cn(loginFormStyles.passwirdTitle, loginFormStyles.passwirdTitle_layout)}>{i18n.t('password')}</h4>
          </div>

          <Form.Item
            className={loginFormStyles.loginForm_item}
            name="password"
            rules={[
              {
                required: true,
                message: i18n.t('input_password')
              }
            ]}
          >
            <Input.Password
              className={cn(loginFormStyles.passwordInput, loginFormStyles.passwordInput_layout)}
              ref={this.secondInputRef}
            />
          </Form.Item>

          <Form.Item className={loginFormStyles.loginForm_item}>
            <Button
              type="primary"
              htmlType="submit"
              block
              ref={this.buttonRef}
              style={{ display: 'none' }}
            >
              {i18n.t('log_in')}
            </Button>
          </Form.Item>
        </Form>
      );
    }

    return (
      <div className={`login-page ${cn(styles.block, styles.block_layout)}`}>
        <div className={cn(styles.flex, styles.flex_layout)}>
          <div className={styles.flex_item}>
            <px-posize
              track-style='{"flexGrow":1}'
              x="0px 706fr 0px"
              y="0fr minmax(0px, max-content) 0fr"
              lg-x="0px 706fr 0px"
              lg-y="0fr minmax(0px, max-content) 0fr"
              xxs-x="0px 706fr 0px"
              xxs-y="0fr minmax(0px, max-content) 0fr"
            >
              <div className={styles.topCover}>
                <ClientLayout partyTitle={this.props.name} />
              </div>
            </px-posize>
          </div>
          <div className={styles.flex_spacer} />
          <div className={styles.flex_item1}>
            <div className={cn(styles.container, styles.container_layout)}>
              <px-posize
                track-style='{"flexGrow":1}'
                x="195fr 46.87% 195fr"
                y="303fr minmax(0px, max-content) 302fr"
                xl-x="50fr 77.48% 50fr"
                xl-y="1fr minmax(0px, max-content) 1fr"
                sm-x="50fr 77.48% 50fr"
                sm-y="50fr minmax(0px, max-content) 50fr"
              >
                <div className={cn(loginFormStyles.title, loginFormStyles.title_layout)}>
                  <h1 className={cn(loginFormStyles.loginTitle, loginFormStyles.loginTitle_layout)}>{i18n.t('log_in')}</h1>
                  <h3 className={cn(loginFormStyles.desc, loginFormStyles.desc_layout)}>{i18n.t('log_in_message_session')}</h3>
                </div>
                {form}
                {this.fakeButton}
              </px-posize>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  name: PropTypes.string.isRequired,
  useEmployeeId: PropTypes.bool.isRequired,
  useAnonymous: PropTypes.bool.isRequired,
  onLoginClick: PropTypes.func.isRequired
};

export default Login;
