/* eslint-disable */
export default {
  "ID": "en-US",
  "not_started": "Activity has not started\r",
  "log_in": "Log in",
  "log_out": "Log out",
  "log_in_loggin": "Logging in",
  "log_in_message": "Log in to watch the content",
  "log_in_message_session": "Log in to join the event",
  "email": "Account",
  "password": "Password",
  "employee_id": "Employee ID",
  "employee_id_lenovo": "IT Code",
  "name": "Name",
  "department": "Department",
  "account_banned": "Account is suspended",
  "account_error": "Wrong account or password",
  "account_error_employeeId": "The ID does not exist.",
  "account_error_employeeId_lenovo": "IT Code does not exist.",
  "account_not_exist": "Account does not exist.",
  "error": "An error occurred\r",
  "input_email": "Please enter account.",
  "input_password": "Please enter password.",
  "input_employee_id": "Please enter your Employee ID\r",
  "input_employee_id_lenovo": "Please enter your IT Code",
  "input_name": "Please enter your Name",
  "input_department": "Please enter your Department",
  "chat_room": "Chatroom\r",
  "award_result": "Winning Results",
  "input_message": "Enter message\r",
  "send": "Send",
  "open_link": "Open link in new tab",
  "open_game": "Enter the game",
  "award_list": "Total Award",
  "award_my": "My Award",
  "awards": "Awards",
  "no_data_awards": "No awards for now.",
  "no_data_sessions": "No sessions for now.",
  "message_updated": "Updated",
  "winner": "Winner",
  "souvenirs": "Souvenirs",
  "dependants": "Number of dependants",
  "input_dependants": "Please choose number of dependants",
  "input_souvenirs": "Please choose souvenirs",
  "choose_souvenirs": "Choose Souvenirs",
  "confirm": "Confirm",
  "confirm_message": "Cannot be modified after confirmation",
  "checkin_qrcode_message": "Please show your QRcode to the staff",
  "game_enter": "Enter the game",
  "game_qrcode_message": "Scan the QR code or click the button to enter the game",
  "": "",
  "mission_title": "Point Collecting Challenges",
  "mission_currently": "Currently",
  "mission_used": "Used",
  "mission_points": "points",
  "mission_redeem": "Redeem",
  "mission": "Mission",
  "mission_staff_only": "Staff Only",
  "mission_redeem_error": "Oops! You don’t have enough points. Please select another gift to redeem.",
  "mission_confirm": "Sure to redeem the prize?",
  "mission_opt_2": "2 points: Animal Headband, Glowing handbell or maraca",
  "mission_opt_3": "3 points: Screaming Chicken, Beer",
  "mission_opt_4": "4 points: Craft Beer, Mini Succulent Planter",
  "yes": "Yes",
  "no": "No",
  "cancel": "Cancel",
  "profile_points_title": "E-Wallet",
  "points_currently": "Currently",
  "points_used": "Used",
  "points_unit": "points",
  "points_detail": "Points Detail",
  "points_add": "Top-up",
  "points_deduct": "Deduct",
  "updated_at": "update time",
  "use_points_btn": "Use Points",
  "booth_drawer_title": "Please enter the booth ID or open your camera to scan the payment QR Code of the Booth.",
  "booth_id_label": "Booth ID",
  "booth_id_error_empty": "Please enter the booth ID",
  "booth_id_error_incorrect": "Please enter the correct booth ID",
  "booth_drawer_enter_amount": "Enter Amount",
  "booth_title": "Booth",
  "transfer_button": "Transfer",
  "close_button": "Close",
  "transfer_desc": "You have paid {{amount}} points to Booth",
  "result_success_title": "Success!",
  "result_fail_title": "OOPS! Failed",
  "result_network_error": "Please check your network connection and try again",
  "points_no_data": "No data for now.",
  "message_booth_not_found": "Booth not found.",
  "message_insufficient_points": "Points balance low.",
  "message_cant_use_point_until_checkin": "Cannot use points until check-in.",
  "transfer_confirm_text": "Confirm payment of {{amount}} points to {{booth}}?"
};
