/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';
import USER_TOKEN_KEY from '../../constants';
import i18n from '../../I18n';

import styles from './CheckInScreen.module.css';

@observer
export default class CheckInScreen extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className={styles.container} style={this.props.isShown ? { transform: 'translateX(0%)' } : null}>
        <CloseOutlined className={styles.closeIcon} onClick={this.props.onClose} />
        <div className={styles.main}>
          <h3 className={styles.subText}>{this.props.subtitle}</h3>
          <h1 className={styles.title}>{this.props.title}</h1>
          <div className={styles.name}>{this.props.name}</div>
          <div className={styles.nickname}>{this.props.nickname}</div>
          <div className={styles.qrcodeContainer}>
            <QRCode
              value={`${window.location && window.location.origin}/api/v1/session/${this.props.sessionId}/checkIn/${Cookies.get(USER_TOKEN_KEY)}`}
              size={210}
            />
          </div>
          <h3 className={styles.subText}>{i18n.t('checkin_qrcode_message')}</h3>
        </div>
      </div>
    );
  }
}

CheckInScreen.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sessionId: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string
};

CheckInScreen.defaultProps = {
  title: '',
  subtitle: null,
  sessionId: null,
  name: null,
  nickname: null
};
